<template>
  <div class="loginHome">
    <div class="tname">
      <div style="height: 76px;"><img class="imglogo" src="../../assets/image/logo.png" /> <span class="logoname">中联燃气</span>
      <div class="rightall" style="margin-right:200px">
          
        <div class="righyitem">
           <p> <img src="../../assets/image/user.png" style="width:22px;margin-right:10px"></p>
            <el-dropdown class="administrator">
              
                <span class="el-dropdown-link" >
                    
                    <span style="color:#0255BF">{{userName}}</span
                    ><i
                    style="color: #0255BF"
                    class="el-icon-arrow-down el-icon--right"
                    ></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                    <div @click="login_out">退出</div>
                    </el-dropdown-item>
                </el-dropdown-menu>
                </el-dropdown>
        </div>
       </div>
      </div>
    </div>
    <div class="content">
        <ul class="showlist">
            <li v-for="(item,index) of MenuArray" :key="index" :class="selectItem==index?'itemli':'itemli'"> 
                <div class="item" @click="pathTo(item,index)">
                    <img :src="item.imgsrc" style="border:none;box-shadow:none;border-radius:0px"/>
                    <p class="title" style="border:none;box-shadow:none;border-radius:0px">{{item.name}}</p>
                </div>
                
            </li>

            <!-- <li> 
                <div class="item">
                    <img src="../../assets/image/1.png">
                    <p class="title">报装系统</p>
                </div>
                
            </li>
            <li> 
                <div class="item">
                    <img src="../../assets/image/2.png">
                    <p class="title">工程管理</p>
                </div>
                
            </li>
           <li> 
                <div class="item">
                    <img src="../../assets/image/3.png">
                    <p class="title">巡线巡检</p>
                </div>
                
            </li>
           <li> 
                <div class="item">
                    <img src="../../assets/image/4.png">
                    <p class="title">入户安检系统</p>
                </div>
                
            </li>
           <li> 
                <div class="item">
                    <img src="../../assets/image/5.png">
                    <p class="title">强维修系统</p>
                </div>
                
            </li>
           <li> 
                <div class="item">
                    <img src="../../assets/image/6.png">
                    <p class="title">客服派单</p>
                </div>
                
            </li>
           <li> 
                <div class="item">
                    <img src="../../assets/image/7.png">
                    <p class="title">GIS系统</p>
                </div>
                
            </li>
            <li> 
                <div class="item">
                    <img src="../../assets/image/8.png">
                    <p class="title">系统设置</p>
                </div>
                
            </li> -->
        </ul>

    </div>
  </div>
</template>

<script>
import {
  LoginOut,
  updateUserPassword,
} from "../../RequestPort/Login/LoginRequest";
export default {
  name: "indexAll",
        data(){
            return {
                HeaderBool:true,
                MenuArray:[],
                userName:'',
                selectItem:0
            }
        },
        methods:{
             getMun(){
                this.MenuArray = JSON.parse(localStorage.getItem('MenuTree'))
                if(this.MenuArray){
                    this.MenuArray.forEach((item,index)=>{
                        if(item.url=='contract'){
                            item.imgsrc=require('../../assets/image/2.png');
                        }
                        if(item.url=='setting'){
                            item.imgsrc=require('../../assets/image/8.png');
                        }
                        if(item.url=='report'){
                            item.imgsrc=require('../../assets/image/1.png');
                        }
                        if(item.url=='homecheck'){
                            item.imgsrc=require('../../assets/image/4.png');
                        }
                        if(item.url=='lineMalking'){
                            item.imgsrc=require('../../assets/image/3.png');
                        }
                      if(item.url=='lineworking'){
                        item.imgsrc=require('../../assets/image/3.png');
                      }
                         if(item.url=='gismanage'){
                            item.imgsrc=require('../../assets/image/3.png');
                        }
                        if(item.url=='Business'){
                            item.imgsrc=require('../../assets/image/4.png');
                        }
                        if(item.url=='serviceOrder'){
                            item.imgsrc=require('../../assets/image/indexkf.png');
                        }
                      if(item.url=='serviceOrderManger'){
                        item.imgsrc=require('../../assets/image/repair.png');
                      }
                    })
                }else{
                    this.$message.error('请重新登录')
                    this.$router.push('/login')
                }
            },
            pathTo(item,index){
                localStorage.setItem("showTitle",item.name);
                localStorage.setItem("tabIndex",index);
                localStorage.setItem("SelectMenuArray",JSON.stringify(this.MenuArray[index].children));
                console.log('/home/'+item.pathUrl)
              //  this.$router.push('/home/'+item.pathUrl);
               this.$store.dispatch('clearVisitedView', [])
              this.$router.push('/home/logohome');
            },
            login_out() {
                LoginOut({}).then((res) => {
                    localStorage.removeItem("MenuTree");
                    localStorage.removeItem("tokenName");
                    localStorage.removeItem("tokenValue");
                    localStorage.removeItem("userName");
                    localStorage.removeItem("userId");
                    localStorage.removeItem("SelectMenuArray");
                    localStorage.removeItem("tabIndex");
                    localStorage.removeItem("showTitle")
                    this.$store.commit('changeSocketState', false)
                    this.$message({
                    message: "注销成功",
                    duration: 1500,
                    });
                    setTimeout(() => {
                    this.$router.push("/login");
                    }, 1500);
      });
    },

        },
        mounted(){
           this.getMun();
           this.selectItem = localStorage.getItem("tabIndex")?localStorage.getItem("tabIndex"):0;
          
           this.userName = localStorage.getItem("userName");
        }
}
</script>

<style  scoped lang="scss">
.rightall{font-size:15px;margin-right: 300px;display: flex;justify-content: right;}
.administrator{height:30px;}
.loginHome{
  width: 100%;
  height: 100vh;
  color: #f4f9ff;
  background-color: #f4f9ff;
  
}
.content{
    width:100%;
    height: 100%; 
    background: url('../../assets/image/home.png') top center no-repeat;
    border: none;
    overflow: auto;text-align: center;
}
.righyitem{
  margin-right:20px;
  display:flex;
  justify-content:right;
  align-items: center;
  color: #0255BF;
}
.tname {
  font-size: 0.5rem;
  text-align: center;
  height: 76px;
  background-color: #fff;
  div {
    display: flex;
    justify-content:right;
    align-items: center;
    
    margin: 0 auto;
    .imglogo {
      margin-left: 8%;
    }
  }
}
.logoname{font-size:36px;margin-left:10px;color:#0255BF;width: 300px;text-align: left;}
.showlist{padding-top: 119px;width: 1288px;margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;}
.showlist li{ text-align: center;margin: 10px;}
.itemli{cursor: pointer; background-color: #fff;color: #0F0F0F;font-size: 24px;width: 300px;height: 270px;border-radius: 8px;box-shadow: 0px 0px 9px 1px rgba(18, 101, 201, 0);border: 1px solid #ffffff;}
.itemli:hover{
    border: 1px solid #096FF2;
    box-shadow: 0px 0px 9px 1px rgba(18, 101, 201, 0.4);

    color: #0F71E2;
}

.title{text-align: center;font-size: 24px;}
.item img{width:200px ;height: 180px;margin-top: 22px}
// .item p{}
.select{
    border: 1px solid #096FF2;
box-shadow: 0px 0px 9px 1px rgba(18, 101, 201, 0.4);
border-radius: 8px;

}
</style>
